export const ReportEndpoint = {
    // Report endpoints
    list: `reports/get-driver-earning`,
    driverEarning: `reports/get-driver-earning-test`,
    companyEaring: `reports/get-company-earning`,
    requestReport: `ride-requests/group-ride-requests`,
    registeredPassenger: `reports/count-passengers-based-on-registered-date`,
    canceledRequest: `reports/count-requests-based-on-cancellation-reason`,
    registeredDrivers: `reports/count-drivers-based-on-registered-date`,

    // Category endpoints
    listVehicleCategory: `categories/get-categories`,
};
