import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {CollectionQuery} from 'shared/model/collection.model';
import {dashboardApiService} from 'store/dashboardApi';

import {dateFormatter} from '../data-formater';
import {ReportEndpoint} from './report-endpoint';

interface request {
    driverId?: string;
    startDate: string;
    endDate: string;
    format: string;
}
const ConfigurationApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            getDriversEaring: builder.query({
                query: (request: request) => ({
                    url: `${ReportEndpoint.list}?id=${request.driverId}&startDate=${request.startDate}&endDate=${request.endDate}&format=${request.format}`,
                    method: 'get',
                   
                }),
            }),

            getCompanyEaring: builder.query({
                query: (request: CollectionQuery) => ({
                    url: `${ReportEndpoint.companyEaring}`,
                    method: 'get',
                    params: collectionQueryBuilder(request),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            ...element,
                            date: dateFormatter(element.date, arg?.format),
                        };
                    });
                    return {
                        items: result,
                        total: response?.total,
                    };
                },
            }),

            getRequestReport: builder.query({
                query: (queary: CollectionQuery) => ({
                    url: `${ReportEndpoint.requestReport}`,
                    method: 'get',
                    params: collectionQueryBuilder(queary),
                }),
            }),
            registeredPassenger: builder.query({
                query: (queary: CollectionQuery) => ({
                    url: `${ReportEndpoint.registeredPassenger}`,
                    method: 'get',
                    params: collectionQueryBuilder(queary),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            ...element,
                            registered_date: dateFormatter(
                                element.registered_date,
                                arg?.format,
                            ),
                        };
                    });
                    return {
                        items: result,
                        total: response?.total,
                    };
                },
            }),
            registeredDrivers: builder.query({
                query: (queary: CollectionQuery) => ({
                    url: `${ReportEndpoint.registeredDrivers}`,
                    method: 'get',
                    params: collectionQueryBuilder(queary),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            ...element,
                            registered_date: dateFormatter(
                                element.registered_date,
                                arg?.format,
                            ),
                        };
                    });
                    return {
                        items: result,
                        total: response?.total,
                    };
                },
            }),
            canceledRequest: builder.query({
                query: (queary: CollectionQuery) => ({
                    url: `${ReportEndpoint.canceledRequest}`,
                    method: 'get',
                    params: collectionQueryBuilder(queary),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            ...element,
                            date: dateFormatter(element.date, arg?.format),
                        };
                    });
                    return {
                        items: result,
                        total: response?.total,
                    };
                },
            }),
            listReportVehicleCategory: builder.query({
                query: () => ({
                    url: `${ReportEndpoint.listVehicleCategory}`,
                    method: 'get',
                }),
            }),
        };
    },
});

export const {
    useLazyGetDriversEaringQuery,
    useLazyGetCompanyEaringQuery,
    useLazyGetRequestReportQuery,
    useLazyRegisteredPassengerQuery,
    useLazyRegisteredDriversQuery,
    useLazyCanceledRequestQuery,

    //category
    useListReportVehicleCategoryQuery,
} = ConfigurationApiService;
