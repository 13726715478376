import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {Outlet, useParams} from 'react-router-dom';
import {CartesianAxis} from 'recharts';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {
    useLazyGetCompanyEaringQuery,
    useListReportVehicleCategoryQuery,
} from '../api/report-api-services';

interface requestModel {
    startDate: string;
    endDate: string;
    format: string;
}
function CompanyEarings() {
    const [isCollapsed, setIsCollapsed] = useState(true);

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const [getData, result] = useLazyGetCompanyEaringQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<any>(formatDate);
    const [endDate, setEndDate] = useState<any>(formatDate);
    const [type, setType] = useState<string>('d');
    const [carType, setCarType] = useState<string>();
    const [category, setCategory] = useState<string>();
    const [request, setRequest] = useState<CollectionQuery>({
        top: 10,
        skip: 0,
        searchFrom: ['total'],
    });

    const [vehicleCategory, setVehicleCategory] = useState<any[]>([]);
    const {data, isLoading} = useListReportVehicleCategoryQuery('');
    useEffect(() => {
        setVehicleCategory(
            data?.items?.filter((data) => data.type === carType),
        );
    }, [data?.items, carType]);

    useEffect(() => {
        if (carType) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'vehicle_type',
                                value: carType,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        }
    }, [carType, type, startDate, endDate]);

    useEffect(() => {
        if (category) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'vehicle_category',
                                value: category,
                                operator: '=',
                            },
                        ],
                        [
                            {
                                field: 'vehicle_type',
                                value: carType,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else if (category) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'vehicle_type',
                                value: carType,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        }
    }, [category, type, startDate, endDate]);

    useEffect(() => {
        getData(request);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/settings/vehicle-category',
        title: 'Company Earings',
        detailUrl: 'detail',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'date', name: 'Date'},
            {key: 'total', name: 'Income'},
            {key: 'total_trips', name: 'trips'},
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <Card className='mt-2'>
            <Card.Body>
                <div className='d-flex gap-3 justify-content-between items-center my-2'>
                    <div>
                        <label>Start Date</label>
                        <input
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                            value={startDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                        />
                    </div>

                    <div>
                        <label>End Date</label>
                        <input
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                            value={endDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                            min={startDate}
                        />
                    </div>

                    <div>
                        <label>Type</label>
                        <select
                            required
                            onChange={(event) => {
                                setType(event.target.value);
                                setRequest((prev) => {
                                    return {
                                        ...prev,
                                        format: event.target.value,
                                    };
                                });
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value='d'>Daily</option>
                            <option value='m'>Monthly</option>
                            <option value='y'>Yearly</option>
                        </select>
                    </div>

                    <div>
                        <label>Car Type</label>
                        <select
                            required
                            onChange={(event) => {
                              
                                setCarType(event.target.value);
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value=''>Select Car Type</option>
                            <option value='taxi'>Taxi</option>
                            <option value='delivery'>Delivery</option>
                        </select>
                    </div>

                    {carType && (
                        <div>
                            <div>
                                <label>Car Category</label>
                                <select
                                    required
                                    onChange={(event) => {
                                        setCategory(event.target.value);
                                    }}
                                    style={{width: '100%', padding: '0.1rem'}}
                                >
                                    <option value=''>
                                        select vehicle category
                                    </option>
                                    {vehicleCategory?.map((cat) => {
                                        return (
                                            <option
                                                key={cat?.id}
                                                value={cat.name}
                                                label={cat?.name}
                                            ></option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <EntityList
                    itemsLoading={loading}
                    items={items || []}
                    total={total || 0}
                    config={config}
                    search={onSearch}
                    pagination={onPagination}
                    filter={onFilter}
                />
            </Card.Body>
        </Card>
    );
}
export default CompanyEarings;
