import React, {useEffect, useState} from 'react';
import {Prev} from 'react-bootstrap/esm/PageItem';
import {Outlet, useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {CollectionQuery} from 'shared/model/collection.model';

import {
    useLazyGetVehicleOwnerByIdQuery,
    useListVehicleQuery,
} from '../api/vehicle-api-service';

function VehicleList() {
    const {id} = useParams();
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['created_by', 'category'],
        filter: [
            [
                {
                    field: 'owner',
                    value: id,
                    operator: '=',
                },
            ],
        ],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['type', 'code', 'model', 'plate_number'],
    });

    const [getData, result] = useLazyGetVehicleOwnerByIdQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(result?.isLoading);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: 'vehicle',
        title: 'Vehicles List',
        detailUrl: 'vehicle/detail',
        showNewButton: true,
        visibleColumn: [
            {key: 'model', name: 'Model'},
            {key: 'code', name: 'Code'},
            {key: 'plate_number', name: 'Plate Number'},
            {key: ['category', 'type'], name: 'Type'},
            {key: 'enabled', name: 'Status'},
            {key: ['category', 'capacity'], name: 'Capacity'},
            {
                key: ['created_by', 'first_name'],
                name: 'Registered By',
                postFix: {key: ['created_by', 'last_name']},
            },
            {key: 'created_at', name: 'Registered At', isDate: true},
            {key: 'enabled', name: 'Status', isBoolean: true},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
           
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {
                ...prev,
                filter: [
                    ...req,
                    [
                        {
                            field: 'owner',
                            value: id,
                            operator: '=',
                        },
                    ],
                ],
            };
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}

export default VehicleList;
