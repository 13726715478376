import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {requestEndpoint} from './request-endpoint';

const AcceptedRequestApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listAcceptedRequest: builder.query({
                query: (query: any) => ({
                    url: `${requestEndpoint.list}`,
                    method: 'get',
                    headers: '',
                    params: collectionQueryBuilder(query),
                }),
                transformResponse: (response: any) => {
                    const result = response?.items.map((element) => {
                        return {
                            ...element,

                            passenger_info: element?.passenger_info?.name
                                ? element?.passenger_info
                                : element?.passenger,
                            fullName: element?.driver
                                ? element?.driver?.first_name +
                                  ' ' +
                                  element?.driver?.last_name
                                : '',
                        };
                    });
                    return {
                        total: response?.total,
                        items: result,
                    };
                },
            }),
            detailRequest: builder.query({
                query: (id: string) => ({
                    url: `${requestEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            deleteAcceptedRequest: builder.mutation({
                query: (id: string) => ({
                    url: `${requestEndpoint.list}`,
                    method: 'delete',
                    headers: '',
                }),
            }),

            completeRequest: builder.mutation({
                query: (id: string) => ({
                    url: `${requestEndpoint.complete}/${id}`,
                    method: 'post',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Request Completed Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong request not completed'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            startRequest: builder.mutation({
                query: (id: string) => ({
                    url: `${requestEndpoint.start}/${id}`,
                    method: 'post',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Request Started Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong request not started'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            cancelRequest: builder.mutation({
                query: (id: string) => ({
                    url: `${requestEndpoint.cancel}/${id}`,
                    method: 'post',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Request Cancelled Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong request not canceled'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListAcceptedRequestQuery,
    useDetailRequestQuery,
    useDeleteAcceptedRequestMutation,
    useCompleteRequestMutation,
    useStartRequestMutation,
    useCancelRequestMutation,
} = AcceptedRequestApiService;
