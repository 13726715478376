import * as yup from 'yup';

export const schema = yup
  .object({
    email: yup.string().required('username is required'),
    password: yup.string().required('password is required'),
  })
  .required()

export const resetSchema = yup
  .object({
    email: yup.string().required('username is required'),
  })
  .required()

export const newPasswordSchema = yup
  .object({
    password: yup.string().required('password is required'),
    conform_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'password must match'),
  })
  .required()
