import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {CollectionQuery} from 'shared/model/collection.model';
import {dashboardApiService} from 'store/dashboardApi';

import {EmergencyReportEndpoint} from './emergency-report-endpoint';

const EmergencyReportApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listEmergencyReport: builder.query({
                query: (query: any) => ({
                    url: `${EmergencyReportEndpoint.list}`,
                    method: 'get',
                    headers: '',
                    params: collectionQueryBuilder(query),
                }),
            }),
            detailEmergencyReport: builder.query({
                query: (id: string) => ({
                    url: `${EmergencyReportEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),

            countUnReportRead: builder.query({
                query: (request: CollectionQuery) => ({
                    url: `${EmergencyReportEndpoint.countUnReportRead}`,
                    method: 'get',
                    params: collectionQueryBuilder(request),
                }),
            }),
        };
    },
});

export const {
    useLazyListEmergencyReportQuery,
    useDetailEmergencyReportQuery,
    useCountUnReportReadQuery,
} = EmergencyReportApiService;
