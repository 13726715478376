import { domain } from "config/constants";
import React, { useEffect, useState } from "react";
import { userService } from "services/auth/user-services";
import io from "socket.io-client";

export const socket = io(domain, {
  transports: ["websocket"],
});

function useSocketIoHook() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState<any>(null);

  const [data, setData] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emergencyReport, setEmergencyReport] = useState<any>(undefined);
  useEffect(() => {
    socket.on("connect", () => {
      const userData = {
        name:
          userService?.currentUser?.first_name +
          " " +
          userService?.currentUser?.last_name,
        email: userService?.currentUser?.email,
        id: userService?.currentUser?.id,
        type: "staff",
      };

      socket.emit("register", userData);
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("notification", function (data) {});

    socket.on("emergency-report", function (res) {
      if (res?.id !== emergencyReport?.id) {
        setEmergencyReport(res);
      }
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("notification");
      socket.off("pong");
    };
  }, [socket]);

  const sendRequest = (request) => {
    socket.emit("dispatch-driver", request);
    socket.on("dispatch-notification", function (data) {});
  };

  return { data, error, loading, sendRequest, emergencyReport };
}

export default useSocketIoHook;
