import React from 'react';
import {useParams} from 'react-router-dom';

import {useDetailNotificationQuery} from '../api/notification-api-services';
import NotificationForm from '../component/notification-form';

function NotificationDetail() {
    const {id} = useParams();
    const {data, isLoading} = useDetailNotificationQuery(id ? id : '');
    return (
        <>
            <NotificationForm
                selectedItem={data}
                selectedItemLoading={isLoading}
            />
        </>
    );
}

export default NotificationDetail;
