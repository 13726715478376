import * as yup from 'yup';

const phoneRegExp =
    /^([+][2][5][1]([7]|[9])[0-9]{8}$)|[+][2][5][1][9][0-9]{8}$/;

export const schema = yup
    .object({
        full_name: yup.string().required('name is required'),
        gender: yup.string().required('Gender is required'),
        phone_number: yup
            .string()
            .required('Phone number is required')
            .matches(phoneRegExp, 'invalid phone number'),
    })
    .required();
