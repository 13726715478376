import {useParams} from 'react-router-dom';

import {useGetPassangerByIdQuery} from '../api/passanger-api-service';
import PassangerDetail from './passanger-detail';

function PassangerLandingPage() {
    const {id} = useParams();
    const {data, isLoading} = useGetPassangerByIdQuery(id ? id : '');
    return (
        <PassangerDetail
            selectedPassanger={data}
            selectedPassangerLoading={isLoading}
        />
    );
}

export default PassangerLandingPage;
