import * as yup from "yup";

export const schema = yup
  .object({
    id: yup.string(),
    amount: yup.string().required("amount is required"),
  })
  .required();

export const changePasswordSchema: yup.SchemaOf<ChangePassword> = yup
  .object({
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup.string().required("Confirm Password is required"),
  })
  .required();

export interface ChangePassword {
  new_password: string;
  confirm_password: string;
}
