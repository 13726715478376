export const awardEndpoint = {
  list: `awards/get-award-types`,
  detail: `awards/get-award-type`,
  create: `awards/create-award-type`,
  update: `awards/update-award-type`,
  delete: `awards/delete-owner`,
  //items
  addItem: `awards/add-award-item`,
  getItem: `awards/get-award-items`,
  removeItem: `awards/remove-award-item`,

  //winners
  generateWinners: `awards/generate-winners`,
  getAwardWinners: `awards/get-award-winners`,
}
