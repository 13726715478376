import {authHeaderService} from 'services/auth/auth-header';
import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './axios.baseQuery';
import { baseUrl } from 'config/constants';


export const dashboardApiService = createApi({
    reducerPath: 'dashboardApiSlice',
    baseQuery: axiosBaseQuery({
        baseUrl: baseUrl,
        auth: authHeaderService.getAuthHeader,
    }),
    tagTypes: [
        'Award',
        'AwardItems',
        'Credit',
        'Dashboard',
        'EmergencyReport',
        'Feedback',
        'Notification',
        'Owner',
        'Passenger',
        'Profile',
        'Provider',
        'ProviderTracking',
        'PublicProvider',
        'PublicVehicle',
        'Reports',
        'Request',
        'Service',
        'Setting',
        'User',
        'Vehicle',
        'CreditHistory',
    ],
    endpoints: () => ({}),
});
