import { googleMapApiKey } from 'config/constants';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import React, {Component} from 'react';

interface MapProps {
    latitude: any;
    longitude: any;
    google: any;
}

class MapContainer extends Component<MapProps> {
    render() {
        return (
            <Map
                initialCenter={{
                    lat: this.props.latitude,
                    lng: this.props.longitude,
                }}
                google={this.props.google}
                zoom={14}
            >
                <Marker
                    position={{  
                        lat: this.props.latitude,
                        lng: this.props.longitude,
                    }}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: googleMapApiKey,
})(MapContainer);
