import React from 'react';

function EmptyState() {
    return (
        <div
            style={{marginTop: '100px'}}
            className=' h-100 d-flex justify-content-center'
        >
            <svg
                width={32}
                height={32}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 128 128'
            >
                <path
                    fill='#fff'
                    d='M112 47L64 30 16 47 16 107 64 124 112 107z'
                />
                <path
                    fill='#fff'
                    d='M112 47L64 30 16 47 16 107 64 124 112 107z'
                />
                <path fill='#fff' d='M16 47L4 67 52 84 64 64zM112 47L64 30' />
                <path
                    fill='#444b54'
                    d='M64,67c-1.2,0-2.4-0.8-2.8-2c-0.6-1.6,0.3-3.3,1.8-3.8l48-17c1.6-0.6,3.3,0.3,3.8,1.8c0.6,1.6-0.3,3.3-1.8,3.8l-48,17C64.7,66.9,64.3,67,64,67z'
                />
                <path
                    fill='#444b54'
                    d='M64,127c-0.6,0-1.2-0.2-1.7-0.5c-0.8-0.6-1.3-1.5-1.3-2.5V95.7c0-1.7,1.3-3,3-3s3,1.3,3,3v24.1l42-14.9V47c0-1.7,1.3-3,3-3s3,1.3,3,3v60c0,1.3-0.8,2.4-2,2.8l-48,17C64.7,126.9,64.3,127,64,127z'
                />
                <path fill='#fff' d='M112 47L64 30M112 47L64 30M64 64L16 47' />
                <path
                    fill='#444b54'
                    d='M13,107c0,1.3,0.8,2.4,2,2.8l48,17c0.3,0.1,0.7,0.2,1,0.2c1.2,0,2.4-0.8,2.8-2c0.6-1.6-0.3-3.3-1.8-3.8l-46-16.3V72.3l-6-2.1V107z'
                />
                <path fill='#fff' d='M124 67L76 84 64 64 112 47z' />
                <path
                    fill='#444b54'
                    d='M126.6,65.5l-12-20c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c0,0,0,0,0,0l-48-17c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.1l-48,17c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.6,0.5l0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0.1l-12,20C1,66.2,0.9,67.2,1.2,68s1,1.5,1.8,1.8l48,17c0.3,0.1,0.7,0.2,1,0.2c1,0,2-0.5,2.6-1.5L64,69.8l9.4,15.7C74,86.5,75,87,76,87c0.3,0,0.7-0.1,1-0.2l48-17c0.8-0.3,1.5-1,1.8-1.8S127,66.2,126.6,65.5z M67,34.2L103,47L67,59.8V34.2z M50.7,80.4l-42.2-15l8.9-14.8l42.2,15L50.7,80.4z M77.3,80.4l-8.9-14.8l42.2-15l8.9,14.8L77.3,80.4z'
                />
            </svg>
            <h3>No Data</h3>
        </div>
    );
}

export default EmptyState;
