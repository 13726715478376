import React, {useEffect, useState} from 'react';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListNotificationQuery} from '../api/notification-api-services';

function NotificationList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['created_by'],
    });

    const [getData, result] = useLazyListNotificationQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: '/notification',
        title: 'Notification List',
        detailUrl: '/notification/detail',
        visibleColumn: [
            {key: 'title', name: 'title'},
            {key: 'to_whom', name: 'To whom'},
            {
                key: ['created_by', 'first_name'],
                name: 'Created By',
                postFix: {key: ['created_by', 'last_name']},
            },
            {
                key: 'created_at',
                name: 'Created At',
                isDate: true,
            },
        ],
        filter: [
            [
                {
                    field: 'is_active',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'is_active',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}

export default NotificationList;
