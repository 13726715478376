import {RoleTypes} from 'features/model/role';
import {User, UserTypes} from 'features/model/user';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Spinner} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import {userService} from 'services/auth/user-services';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';

import {
    useGetUserProfileQuery,
    useUpdateProfileMutation,
} from '../api/profile-api-service';
import ChangePasswordForm from './change-password';
import {profileSchema, schema} from './form-validation';

interface ProfileFormProps {
    viewMode: string;
    title: string;
    selectedItemLoading?: boolean;
}
function ProfileForm(props: ProfileFormProps) {
    const user = userService.currentUser;
    const {data, isLoading} = useGetUserProfileQuery('');

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [
        updateProfile,
        {isLoading: profileUpdating, isSuccess, data: updatedProfile},
    ] = useUpdateProfileMutation();

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const onUpdate = (updatedData: any) => {
        updateProfile(updatedData);
    };

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm<User>({
        defaultValues: {
            is_active: true,
        },
        resolver: yupResolver(profileSchema),
        mode: 'onBlur',
    });

    useEffect(() => {
        if (data) {
            reset(data);
            localStorage.setItem('user', JSON.stringify(data));
        }
    }, [data, reset]);

    useEffect(() => {
        if (updatedProfile && isSuccess) {
            localStorage.setItem('user', JSON.stringify(updatedProfile.user));
            window.location.reload();
        }
    }, [isSuccess, updatedProfile]);
    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <>
                    <Card>
                        <Card.Header className='d-flex justify-content-between'>
                            <div> {props.title}</div>
                            <div className='d-flex'>
                                <Button size='sm' onClick={onCollapsed}>
                                    {isCollapsed ? 'Expand' : 'Collapse'}
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body className={isCollapsed ? 'd-none' : ''}>
                            <Form onSubmit={handleSubmit(onUpdate)}>
                                <div className='d-flex mb-4'>
                                    <div className='w-50'>
                                        <Form.Group>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    First Name
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.first_name
                                                        ? true
                                                        : false
                                                }
                                                {...register('first_name')}
                                                type='text'
                                                placeholder='enter First Name'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.first_name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <div className='w-50 mx-4'>
                                        <Form.Group>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Last Name
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.last_name
                                                        ? true
                                                        : false
                                                }
                                                {...register('last_name')}
                                                placeholder='enter last name'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.last_name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className='d-flex mb-4'>
                                    <div className='w-50'>
                                        <Form.Group>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Email
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.email ? true : false
                                                }
                                                {...register('email')}
                                                type='text'
                                                placeholder='enter email'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.email?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <div className='w-50 mx-4'>
                                        <Form.Group>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Phone Number
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.phone_number
                                                        ? true
                                                        : false
                                                }
                                                {...register('phone_number')}
                                                placeholder='enter phone number'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.phone_number?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className='d-flex mb-4'>
                                    <div className='w-50'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formEmergencyContactNumber'
                                        >
                                            <Form.Label>
                                                Emergency contact number
                                            </Form.Label>
                                            <Form.Control
                                                {...register(
                                                    'emergency_contact',
                                                )}
                                                type='text'
                                                isInvalid={
                                                    errors?.emergency_contact
                                                        ? true
                                                        : false
                                                }
                                                placeholder='enter emergence contact number'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {
                                                    errors?.emergency_contact
                                                        ?.message
                                                }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='w-50 mx-4'>
                                        <Form.Group>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Gender
                                                </Form.Label>
                                            </div>
                                            <Form.Select
                                                isInvalid={
                                                    errors?.gender
                                                        ? true
                                                        : false
                                                }
                                                {...register('gender')}
                                            >
                                                <option value=''>
                                                    Select gender
                                                </option>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.gender?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {/* <div className="w-25 mx-4">
                    <Form.Group>
                      <div className="form-group required">
                        <Form.Label className="control-label">
                          Is Active
                        </Form.Label>
                      </div>
                      <Form.Check
                        isInvalid={errors?.is_active ? true : false}
                        {...register('is_active')}
                      />
                    </Form.Group>
                  </div> */}
                                    </div>
                                </div>

                                <div className='d-flex mb-4'>
                                    <div className='w-100 mx-2'>
                                        <Card>
                                            <Card.Header>Address</Card.Header>
                                            <Card.Body>
                                                <div className='d-flex mb-2'>
                                                    <div className='w-50 mx-1'>
                                                        <Form.Group
                                                            className='mb-2'
                                                            controlId='formCity'
                                                        >
                                                            <div className='form-group required'>
                                                                <Form.Label className='control-label'>
                                                                    City
                                                                </Form.Label>
                                                            </div>
                                                            <Form.Control
                                                                isInvalid={
                                                                    errors
                                                                        ?.address
                                                                        ?.city
                                                                        ? true
                                                                        : false
                                                                }
                                                                {...register(
                                                                    'address.city',
                                                                )}
                                                                type='text'
                                                                placeholder='enter city'
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {
                                                                    errors
                                                                        ?.address
                                                                        ?.city
                                                                        ?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group
                                                            className='mb-2'
                                                            controlId='formSubCity'
                                                        >
                                                            <div className='form-group required'>
                                                                <Form.Label className='control-label'>
                                                                    SubCity
                                                                </Form.Label>
                                                            </div>
                                                            <Form.Control
                                                                isInvalid={
                                                                    errors
                                                                        ?.address
                                                                        ?.sub_city
                                                                        ? true
                                                                        : false
                                                                }
                                                                {...register(
                                                                    'address.sub_city',
                                                                )}
                                                                type='text'
                                                                placeholder='enter SubCity'
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {
                                                                    errors
                                                                        ?.address
                                                                        ?.sub_city
                                                                        ?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>

                                                    <div className='w-50 mx-1'>
                                                        <Form.Group
                                                            className='mb-2'
                                                            controlId='formWoreda'
                                                        >
                                                            <div className='form-group required'>
                                                                <Form.Label className='control-label'>
                                                                    Woreda
                                                                </Form.Label>
                                                            </div>
                                                            <Form.Control
                                                                isInvalid={
                                                                    errors
                                                                        ?.address
                                                                        ?.woreda
                                                                        ? true
                                                                        : false
                                                                }
                                                                {...register(
                                                                    'address.woreda',
                                                                )}
                                                                type='text'
                                                                placeholder='enter woreda'
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {
                                                                    errors
                                                                        ?.address
                                                                        ?.woreda
                                                                        ?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId='formHouseNumber'>
                                                            <div className='form-group required'>
                                                                <Form.Label className='control-label'>
                                                                    House Number
                                                                </Form.Label>
                                                            </div>
                                                            <Form.Control
                                                                isInvalid={
                                                                    errors
                                                                        ?.address
                                                                        ?.house_number
                                                                        ? true
                                                                        : false
                                                                }
                                                                {...register(
                                                                    'address.house_number',
                                                                )}
                                                                type='text'
                                                                placeholder='enter house number'
                                                                // defaultValue={props?.selectedItem?.address?.house_number}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {
                                                                    errors
                                                                        ?.address
                                                                        ?.house_number
                                                                        ?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>

                                <div className='d-flex'>
                                    <VButton
                                        type='submit'
                                        disabled={props.viewMode === 'new'}
                                        className='mx-2'
                                        variant='warning'
                                        isLoading={profileUpdating}
                                    >
                                        <IconEdit />
                                        Update
                                    </VButton>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>

                    <ChangePasswordForm />
                </>
            )}
        </>
    );
}

export default ProfileForm;
