import React from 'react';

import {useLoadScript} from '@react-google-maps/api';

import Map from './map';
import { googleMapApiKey } from 'config/constants';

function Home() {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: googleMapApiKey,
        libraries: ['places'],
    });
    if (!isLoaded) return <div>Loading map...</div>;
    return <Map />;
}

export default Home;
