import {useParams} from 'react-router-dom';

import {useGetOwnerByIdQuery} from '../api/owner-api-service';
import OwnerForm from '../component/owner-form';

function OwnerDetail() {
    const {id} = useParams();
    const {data, isLoading} = useGetOwnerByIdQuery(id ? id : '');
    return (
        <>
            <OwnerForm
                title='Owner Detail'
                viewMode='detail'
                selectedItem={data}
                selectedItemLoading={isLoading}
            />
        </>
    );
}

export default OwnerDetail;
