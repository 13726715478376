import * as yup from 'yup';

export const vehicleSchema = yup
    .object({
        id: yup.string(),
        model: yup.string().required('model is required'),
        code: yup.string().required('code is required'),
        plate_number: yup.string().required('plate number is required'),
        color: yup.string().required('color is required'),
        category: yup.string().required('category is required'),
        type: yup.string().required('type is required'),
    })
    .required();
