import {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import Select from 'react-select';
import {CollectionQuery} from 'shared/model/collection.model';

import {
    useLazyListVehicleProviderQuery,
    useReassignDriverMutation,
} from '../api/provider-api-services';
import styles from './style.module.css';

export interface ModalProps {
    showModal: boolean;
    handleClose: any;
    driver: any;
}
function ModalReassignDriver(props: ModalProps) {
    const [request, setRequest] = useState<CollectionQuery>({
        searchFrom: ['plate_number'],
    });
    const [searchQueary, setSearchQueary] = useState<string>();
    const [selectedValue, setSelectedValue] = useState<any>();
    const [reassignDriver, {data, isSuccess: isSuccessReassign}] =
        useReassignDriverMutation();
    const [getData, {data: vehicles, isLoading, isSuccess}] =
        useLazyListVehicleProviderQuery();
    useEffect(() => {
        if (searchQueary) getData(request);
    }, [request]);

    useEffect(() => {
        if (searchQueary) {
            setRequest((prev) => {
                return {
                    ...prev,
                    search: searchQueary,
                };
            });
        }
    }, [searchQueary]);
    const handleOnChange = (e) => {
        setSelectedValue(e);
    };

    const onReassign = () => {
        const sendData = {
            driver_id: props.driver.id,
            vehicle_id: selectedValue.value,
        };
        reassignDriver(sendData);
    };
    useEffect(() => {}, [isSuccessReassign, data]);
    return (
        <>
            <Modal
                fullscreen='xl-down'
                size='lg'
                centered
                show={props.showModal}
                onHide={props.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reassign Driver to vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='border mt-2 mx-2 pt-2'>
                        {/* Name */}
                        <div className='p-2 pt-0 pb-0 d-flex'>
                            <div className={`p-2 w-25 ${styles.listKey} `}>
                                Name
                            </div>
                            <div className={`p-2 w-75 ${styles.list} `}>
                                {props.driver?.first_name}{' '}
                                {props.driver?.last_name}
                            </div>
                        </div>

                        {/* Gender */}
                        <div className='p-2 pt-0 pb-0 d-flex'>
                            <div className={`p-2 w-25 ${styles.listKey} `}>
                                Gender
                            </div>
                            <div className={`p-2 w-75 ${styles.list} `}>
                                {props.driver?.gender}
                            </div>
                        </div>

                        {/* Phone */}
                        <div className='p-2 pt-0 pb-0 d-flex'>
                            <div className={`p-2 w-25 ${styles.listKey} `}>
                                Phone Number
                            </div>
                            <div className={`p-2 w-75 ${styles.list} `}>
                                {props.driver?.phone_number}
                            </div>
                        </div>
                        {/* Emergency */}
                        {props.driver?.emergency_contact && (
                            <div className='p-2 pt-0 pb-0 d-flex'>
                                <div className={`p-2 w-25 ${styles.listKey} `}>
                                    {' '}
                                    Emergency Contact
                                </div>
                                <div className={`p-2 w-75 ${styles.list} `}>
                                    {props.driver?.emergency_contact}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className=' d-flex items-center '>
                        <div className='w-75'>
                            <Form.Group>
                                {/* <div className="form-group required"> */}
                                <Form.Label className='control-label'>
                                    Vehicle
                                </Form.Label>
                                {/* </div> */}

                                <Select
                                    isClearable
                                    isSearchable={true}
                                    className='react-dropdown'
                                    classNamePrefix='dropdown'
                                    options={vehicles}
                                    value={selectedValue}
                                    onChange={handleOnChange}
                                    onInputChange={(e) => {
                                        setSearchQueary(e);
                                    }}
                                />
                            </Form.Group>
                        </div>

                        <div className='d-flex align-items-center'>
                            <Button
                                style={{marginTop: '22px'}}
                                size='sm'
                                className='mx-2'
                                onClick={onReassign}
                            >
                                Reassign
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalReassignDriver;
