export const vehicleEndpoint = {
    list: `vehicles/get-vehicles`,
    detail: `vehicles/get-vehicle`,
    create: `vehicles/create-vehicle`,
    update: `vehicles/update-vehicle`,
    delete: `vehicles/delete-vehicle`,
    listOwner: `owners/get-owners`,
    getOwnerById: `owners/get-owner`,
    activateVehicle: `vehicles/activate-vehicle`,
    blockVehicle: `vehicles/block-vehicle`,

    //document
    uploadDocument: `vehicles/update-document`,

    getVehicleByOwner: `vehicles/get-vehicles-by-owner`,

    // configuration
    listVehicleCategory: `categories/get-categories`,
};
