import React from 'react';
import { useParams } from 'react-router-dom';

import { useDetailRequestQuery } from '../api/accepted_requests-api-service';
import RequestDetail from './requests-detail';

function RequestLandingPage() {
  const { id } = useParams()
  const { data, isLoading } = useDetailRequestQuery(id ? id : '')

  return (
    <RequestDetail selectedRequest={data} selectedRequestLoading={isLoading} />
  )
}

export default RequestLandingPage
