import * as yup from "yup";

const phoneRegExp = /^([+][1])([0-9]{10}$)/;
export const schema = yup
  .object({
    address: yup
      .object()
      .shape({
        city: yup.string().required("City is required"),
        sub_city: yup.string().required("State is required"),
        house_number: yup.string().optional(),
        woreda: yup.string().required("Street name is required"),
      })
      .required(),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required"),
    gender: yup.string().required("Gender is required"),
    user_type: yup.string().required("User type is required"),
    emergency_contact: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Emergency contact is required"),
  })
  .required();
