// Global/Shared Sass
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './assets/scss/main.scss';

import AppRoutes from 'App-routes';

function App() {
  // const lastItem = window.location.href.substring(
  //   window.location.href.lastIndexOf('/') + 1,
  // )
  // if (lastItem === 'login') {
  //   storageServices.clear()
  // }
  // const currentUser = userService.currentUser
  // if (currentUser) {
  return <AppRoutes />
  // } else if (lastItem === 'login') {
  //   return <LoginForm />
  // }
  // if (lastItem === 'reset-password') {
  //   return <ResetPasswordForm />
  // } else {
  //   return <ForgetPasswordForm />
  // }
}

export default App
