import 'firebase/database';
import 'firebase/firestore';

import firebase from 'firebase/app';
import { firebaseApiKey, firebaseAppId, firebaseDatabaseUrl, firebaseProjectId } from 'config/constants';

export const firebaseConfig = {

    apiKey: firebaseApiKey,
    databaseURL: firebaseDatabaseUrl,
    projectId: firebaseProjectId,
    appId: firebaseAppId,
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export {firebase};
