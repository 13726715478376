import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';

import {render} from '@testing-library/react';

import {dashboardApiService} from '../../../store/dashboardApi';
import {passangerEndpoint} from './passanger-endpoint';

export const PassangerApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listPassanger: builder.query({
                query: (query?: any) => ({
                    url: `${passangerEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getPassangerById: builder.query({
                query: (id: string) => ({
                    url: `${passangerEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            createPassanger: builder.mutation({
                query: (newData: any) => ({
                    url: `${passangerEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Passenger Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Passenger not created'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            activatePassenger: builder.mutation({
                query: (id: string) => ({
                    url: `${passangerEndpoint.activatePassenger}`,
                    method: 'post',
                    data: {passenger_id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Passenger Activated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong passenger not activated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            blockPassenger: builder.mutation({
                query: (id: string) => ({
                    url: `${passangerEndpoint.blockPassenger}`,
                    method: 'post',
                    data: {passenger_id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Passanger Blocked Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong passenger not blocked'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            listPassengerTripHistory: builder.query({
                query: ({request, id}) => ({
                    url: `${passangerEndpoint.listPassengerTripHistory}/${id}`,
                    method: 'get',
                    params: collectionQueryBuilder(request),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            ...element,
                            fullName: element?.driver?.first_name
                                ? `${element?.driver?.first_name}  ${element?.driver?.last_name}`
                                : '',
                        };
                    });
                    return {
                        items: result,
                        total: response?.total,
                    };
                },
            }),
        };
    },
});

export const {
    useActivatePassengerMutation,
    useBlockPassengerMutation,
    useCreatePassangerMutation,
    useGetPassangerByIdQuery,
    useLazyListPassangerQuery,
    useLazyGetPassangerByIdQuery,
    useLazyListPassengerTripHistoryQuery,
} = PassangerApiService;
