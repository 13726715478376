export const passangerEndpoint = {
    list: `passengers/get-passengers`,
    detail: `passengers/get-passenger`,
    create: `passengers/create-passenger`,
    delete: `passengers/delete-passenger`,

    activatePassenger: `passengers/activate-passenger`,
    blockPassenger: `passengers/block-passenger`,

    //document
    uploadDocument: `passengers/update-document`,

    listPassengerTripHistory: `ride-requests/get-passenger-trips`,
};
