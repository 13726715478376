export const applicationSetting: ApplicationSetting = {
  name: "Drumm Farm",
  role: [
    {
      name: "Admin",
      menu: [
        { name: "Dashboard", url: "/dashboard" },
        { name: "Registration", url: "/about" },
        { name: "Vehicle", url: "/public-vehicle" },
        { name: "Approved Volunteers", url: "/public-driver" },
        { name: "Youth", url: "/Passanger" },
        { name: "Requests", url: "/requests" },
        { name: "Push Notification", url: "/notification" },
        { name: "Feedback", url: "/feedback" },
        { name: "Review", url: "/review" },
        { name: "Reports", url: "/report" },
        { name: "Award", url: "/award" },
        // {name: "Delivery Requests", url: '/delivery-requests'},
        { name: "Emergency Report", url: "/emergency-report" },
        { name: "Settings", url: "/settings" },
        { name: "Users", url: "/users" },
      ],
    },
    {
      name: "Operator",
      menu: [
        { name: "Dashboard", url: "/dashboard" },
        { name: "Vehicle", url: "/public-vehicle" },
        { name: "Approved Volunteers", url: "/public-driver" },
        { name: "Youth", url: "/Passanger" },
        { name: "Requests", url: "/requests" },
        { name: "Dispatcher", url: "/driver_tracking" },
        { name: "Feedback", url: "/feedback" },
        { name: "Review", url: "/review" },
        { name: "Award", url: "/award" },
        { name: "Emergency Report", url: "/emergency-report" },
        // {name: "Delivery Requests", url: '/delivery-requests'},
      ],
    },
    {
      name: "BankTeller",
      menu: [
        { name: "Dashboard", url: "/dashboard" },
        { name: "Bank Credit", url: "/bank-credit" },
      ],
    },
    {
      name: "SuperAdmin",
      menu: [
        { name: "Dashboard", url: "/dashboard" },
        { name: "Registration", url: "/about" },
        { name: "Vehicle", url: "/public-vehicle" },
        { name: "Approved Volunteers", url: "/public-driver" },
        { name: "Youth", url: "/Passanger" },
        { name: "Requests", url: "/requests" },
        { name: "Dispatcher", url: "/driver_tracking" },
        { name: "Push Notification", url: "/notification" },
        { name: "Feedback", url: "/feedback" },
        { name: "Review", url: "/review" },
        // {name: 'Bank Credit', url: '/bank-credit'},
        { name: "Reports", url: "/report" },
        { name: "Award", url: "/award" },
        // {name: "Delivery Requests", url: '/delivery-requests'},
        { name: "Emergency Report", url: "/emergency-report" },
        { name: "Settings", url: "/settings" },
        { name: "Users", url: "/users" },
      ],
    },
    {
      name: "FinanceOfficer",
      menu: [
        { name: "Dashboard", url: "/dashboard" },
        { name: "Approved Volunteers", url: "/public-driver" },
        { name: "Reports", url: "/report" },
        { name: "Award", url: "/award" },
      ],
    },
  ],
};

export interface Menu {
  name: string;
  url: string;
}

export interface Role {
  name: string;
  menu: Menu[];
}
export interface ApplicationSetting {
  name: string;
  role: Role[];
}
