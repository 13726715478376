import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {CollectionQuery} from 'shared/model/collection.model';

import {render} from '@testing-library/react';

import {dashboardApiService} from '../../../store/dashboardApi';
import {providerEndpoint} from './provider-endpoint';

export const ProviderApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listProvider: builder.query({
                query: (query?: any) => ({
                    url: `${providerEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getProviderById: builder.query({
                query: (id: string) => ({
                    url: `${providerEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            getVehicleByOwner: builder.query({
                query: (owner_id) => ({
                    url: `${providerEndpoint.getVehicleByOwner}?owner_id=${owner_id}`,
                    method: 'get',
                }),
            }),
            createProvider: builder.mutation({
                query: (newData: any) => ({
                    url: `${providerEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Provider Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateProvider: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${providerEndpoint.update}?driver_id=${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Provider Updated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            updateDriverPhoto: builder.mutation({
                query: ({id, profile_image}) => ({
                    url: `${providerEndpoint.updateDriverPhoto}/${id}`,
                    method: 'post',
                    data: profile_image,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Provider Photo Updated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong provider photo not created'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            activateProvider: builder.mutation({
                query: (id: string) => ({
                    url: `${providerEndpoint.activateDriver}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Provider Activated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong provider not activated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            blockProvider: builder.mutation({
                query: (id: string) => ({
                    url: `${providerEndpoint.blockDriver}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Provider Blocked Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong provider not blocked'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteProvider: builder.query({
                query: (id: string) => ({
                    url: `${providerEndpoint.delete}`,
                    method: 'delete',
                }),
            }),
            listVehicleOwnerProvider: builder.query({
                query: (query?: any) => ({
                    url: `${providerEndpoint.listOwner}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getVehicleOwnerProviderById: builder.query({
                query: (query: any) => ({
                    url: `${providerEndpoint.listVehicle}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            listVehicleProvider: builder.query({
                query: (query: CollectionQuery) => ({
                    url: `${providerEndpoint.listVehicle}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
                transformResponse: (response: any, meta, arg) => {
                    const result = response?.items?.map((element) => {
                        return {
                            value: element.id,
                            label: `${element.plate_number}, ${element.model}  , ${element.type}`,
                        };
                    });
                    return result;
                },
            }),
            getVehicleProviderById: builder.query({
                query: (query: any) => ({
                    url: `${providerEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),

            uploadProviderPassport: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${providerEndpoint.uploadDocument}?driver_id=${updatedData.driver_id}`,
                    method: 'post',
                    data: updatedData.file,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='ID uploaded successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong ID not Uploaded'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),

            reassignDriver: builder.mutation({
                query: (request: any) => ({
                    url: `${providerEndpoint.reassign}`,
                    method: 'post',
                    data: request,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Driver Reassigned successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Driver reassigned successfully'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListProviderQuery,
    useGetProviderByIdQuery,
    useGetVehicleByOwnerQuery,
    useCreateProviderMutation,
    useUpdateProviderMutation,
    useUpdateDriverPhotoMutation,
    useDeleteProviderQuery,
    useLazyGetVehicleOwnerProviderByIdQuery,
    useLazyListVehicleOwnerProviderQuery,
    useLazyGetVehicleProviderByIdQuery,
    useLazyListVehicleProviderQuery,
    useUploadProviderPassportMutation,
    useActivateProviderMutation,
    useBlockProviderMutation,
    useReassignDriverMutation,
} = ProviderApiService;
