import {useParams} from 'react-router-dom';

import VehicleCategoryForm from '../component/vehicle-category';

function NewVehicleCategory() {
    const {id} = useParams();
    return (
        <VehicleCategoryForm viewMode='new' title='Add new Vehicle Category' />
    );
}

export default NewVehicleCategory;
