import {useParams} from 'react-router-dom';

import {useGetVehicleCategoryByIdQuery} from '../api/vehicle-category-api-services';
import VehicleCategoryForm from '../component/vehicle-category';

function VehicleCategoryDetail() {
    const {id} = useParams();
    const {data, isLoading} = useGetVehicleCategoryByIdQuery(id ? id : '');
    return (
        <VehicleCategoryForm
            viewMode='detail'
            title='Vehicle Category Detail'
            selectedItem={data}
            selectedItemLoading={isLoading}
        />
    );
}

export default VehicleCategoryDetail;
