import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {userEndpoint} from './user-endpoint';

const UserApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listUser: builder.query({
                query: (query) => ({
                    url: `${userEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getByIdUser: builder.query({
                query: (id: string) => ({
                    url: `${userEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            createUser: builder.mutation({
                query: (newData: any) => ({
                    url: `${userEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='User Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong user not created'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateUser: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${userEndpoint.update}?user_id=${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='User Updated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong user not updated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteUser: builder.mutation({
                query: (id: string) => ({
                    url: `${userEndpoint.delete}`,
                    method: 'delete',
                }),
            }),

            activateUser: builder.mutation({
                query: (id: string) => ({
                    url: `${userEndpoint.activate}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='User Activated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong unable to activate a user'
                                show={true}
                                type={'success'}
                            />,
                        );
                    }
                },
            }),
            blockUser: builder.mutation({
                query: (id: string) => ({
                    url: `${userEndpoint.block}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='User blocked successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong unable to block a user'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListUserQuery,
    useGetByIdUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useActivateUserMutation,
    useBlockUserMutation,
} = UserApiService;
