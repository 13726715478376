import {VehicleCategory} from 'features/model/vehicle-category';
import MessagingServices from 'shared/messaging-services';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {ConfigurationEndpoint} from './configuration-endpoint';

const VehicleCategoryApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listVehicleCategory: builder.query({
                query: () => ({
                    url: `${ConfigurationEndpoint.listCategory}`,
                    method: 'get',
                }),
            }),
            getVehicleCategoryById: builder.query({
                query: (id: string) => ({
                    url: `${ConfigurationEndpoint.detailCategory}/${id}`,
                    method: 'get',
                }),
            }),
            createVehicleCategory: builder.mutation({
                query: (newData: VehicleCategory) => ({
                    url: `${ConfigurationEndpoint.createCategory}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Vehicle Category Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong vehicle Category not created'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateVehicleCategory: builder.mutation({
                query: (updatedData: VehicleCategory) => ({
                    url: `${ConfigurationEndpoint.updateCategory}/${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Vehicle Category updated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Vehicle Category not updated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteVehicleCategory: builder.mutation({
                query: (id: string) => ({
                    url: `${ConfigurationEndpoint.deleteCategory}`,
                    method: 'delete',
                }),
            }),

            activateVehicleCategory: builder.mutation({
                query: (id: string) => ({
                    url: `${ConfigurationEndpoint.activateCategory}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Vehicle category activated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong vehicle category not activated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deactivateVehicleCategory: builder.mutation({
                query: (id: string) => ({
                    url: `${ConfigurationEndpoint.deactivateCategory}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Vehicle category deactivated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong vehicle category not deactivated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListVehicleCategoryQuery,
    useGetVehicleCategoryByIdQuery,
    useCreateVehicleCategoryMutation,
    useUpdateVehicleCategoryMutation,
    useDeleteVehicleCategoryMutation,
    useActivateVehicleCategoryMutation,
    useDeactivateVehicleCategoryMutation,
} = VehicleCategoryApiService;
