import { ChangePassword } from 'features/model/change-password'
import { User } from 'features/model/user'
import * as yup from 'yup'
const phoneRegExp = /^([+][2][5][1]([7]|[9])[0-9]{8}$)|[+][2][5][1][9][0-9]{8}$/
export const schema: yup.SchemaOf<ChangePassword> = yup
  .object({
    current_password: yup.string().required('Old Password is required'),
    new_password: yup.string().required('Old Password is required'),
    confirm_password: yup.string().required('Old Password is required'),
  })
  .required()

export const profileSchema = yup.object().shape(
  {
    address: yup
      .object()
      .shape({
        city: yup.string().required('City is required'),
        sub_city: yup.string().required('SubCity is required'),
        house_number: yup.string().required('House number is required'),
        woreda: yup.string().required('Woreda is required'),
      })
      .required(),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phone_number: yup
      .string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'invalid phone number'),
    gender: yup.string().required('Gender is required'),
    emergency_contact: yup.string().when('emergency_contact', (val, schema) => {
      if (val?.length > 0) {
        return yup.string().matches(phoneRegExp, 'invalid phone number')
      } else {
        return yup.string().notRequired()
      }
    }),
  },
  [['emergency_contact', 'emergency_contact']],
)
