import React from 'react';
import {useParams} from 'react-router-dom';

import {
    useGetVehicleByIdQuery,
    useListOwnVehicleCategoryQuery,
} from '../api/vehicle-api-service';
import DocumentFrom from '../component/document-form';
import DriverForm from '../component/driver-form';
import VehicleForm from '../component/vehicle-form';

function VehicleDetail() {
    const {id} = useParams();

    const {data, isLoading} = useGetVehicleByIdQuery(id ? id : '');
    const {data: category, isLoading: loading} =
        useListOwnVehicleCategoryQuery('');
    return (
        <>
            <VehicleForm
                viewMode='detail'
                title='Vehicle Detail'
                selectedItem={data}
                selectedItemLoading={isLoading}
                vehicleCategory={{items: category?.items, isLoading: loading}}
            />
            {/* <DriverForm selectedItem={data?.drivers} selectedItemLoading={isLoading} /> */}
            <DocumentFrom />
        </>
    );
}

export default VehicleDetail;
