import React, {useState} from 'react';
import {Button, Card, Form, InputGroup, Spinner} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconEye} from '@tabler/icons';

import {useNewPasswordMutation} from '../api/auth-api-service';
import {history} from '../history';
import {newPasswordSchema} from './form-validation';

function ResetPasswordForm() {
    const [isReset, setIsReset] = useState<boolean>(false);
    let [searchParams, setSearchParams] = useSearchParams();

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(newPasswordSchema),
        mode: 'onBlur',
    });
    const [reset, {data, isSuccess, isError, isLoading}] =
        useNewPasswordMutation();
    const [passwordShown, setPasswordShown] = useState(false);

    const onReset = async (request: any) => {
        const sendData = {
            ...request,
            user_id: searchParams.get('id'),
            token: searchParams.get('token'),
        };
        await reset(sendData);
        setIsReset(true);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <div
            style={{height: '100vh', backgroundColor: '#fafbf6'}}
            className='d-flex justify-content-center align-items-center'
        >
            <div className='w-75 d-flex justify-content-center align-items-center'>
                <div className='w-50 mx-4'>
                    <div style={{textAlign: 'center'}}>
                        <h4>Reset Password</h4>
                    </div>
                    <div>
                        <Card className=' p-4 rounded w-100'>
                            <Form onSubmit={handleSubmit(onReset)}>
                                {!isSuccess && (
                                    <>
                                        <Form.Group className='mb-4'>
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    {...register('password', {
                                                        required: true,
                                                    })}
                                                    type={
                                                        passwordShown
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    isInvalid={
                                                        errors?.password
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder='enter password'
                                                    aria-describedby='basic-addon1'
                                                ></Form.Control>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <div
                                                        onClick={togglePassword}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <IconEye
                                                            style={{
                                                                height: 14,
                                                                width: 14,
                                                            }}
                                                        />
                                                    </div>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className='mb-4'>
                                            <Form.Label>
                                                Conform Password
                                            </Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    {...register(
                                                        'conform_password',
                                                        {required: true},
                                                    )}
                                                    type={
                                                        passwordShown
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    isInvalid={
                                                        errors?.conform_password
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder='conform password'
                                                    aria-describedby='basic-addon1'
                                                ></Form.Control>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <div
                                                        onClick={togglePassword}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <IconEye
                                                            style={{
                                                                height: 14,
                                                                width: 14,
                                                            }}
                                                        />
                                                    </div>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        {isError && (
                                            <span className='text-danger mb-4'>
                                                Something went wrong, Please try
                                                again
                                            </span>
                                        )}
                                        <Button
                                            className='w-100'
                                            variant='primary'
                                            type='submit'
                                        >
                                            {isLoading ? (
                                                <Spinner
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                    }}
                                                    animation='border'
                                                    role='status'
                                                ></Spinner>
                                            ) : (
                                                ''
                                            )}{' '}
                                            Reset
                                        </Button>
                                    </>
                                )}

                                {isSuccess && (
                                    <span className='text-success '>
                                        Your Password changed successfully
                                    </span>
                                )}

                                <div className='mt-4 mb-0'>
                                    <Link
                                        to='/login'
                                        style={{
                                            fontSize: '12px',
                                            textDecoration: 'none',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p>Return to login</p>
                                    </Link>
                                </div>
                            </Form>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordForm;
