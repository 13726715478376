import {storageServices} from 'services/storage-service';
import {dashboardApiService} from 'store/dashboardApi';

import {history} from '../history';
import {authEndpoint} from './auth-endpoint';

const AuthApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            login: builder.mutation({
                query: (newData: any) => ({
                    url: `${authEndpoint.login}`,
                    method: 'POST',
                    data: newData,
                }),
                async onQueryStarted(unknown, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        await storageServices.setItem(
                            'token',
                            JSON.stringify(data?.token),
                        );
                        await storageServices.setItem(
                            'refreshToken',
                            JSON.stringify(data?.refresh_token),
                        );
                        await storageServices.setItem(
                            'user',
                            JSON.stringify(data?.user),
                        );
                        localStorage.setItem(
                            'currentRole',
                            JSON.stringify(data?.user?.roles[0]),
                        );
                        history.push('/dashboard');
                        window.location.reload();
                    } catch (error) {
                        // history.push('/dashboard')
                        // window.location.reload()
                    }
                },
            }),

            resetPassword: builder.mutation({
                query: (request: any) => ({
                    url: `${authEndpoint.resetPassword}`,
                    method: 'POST',
                    data: request,
                }),
                async onQueryStarted(unknown, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                    } catch (error) {}
                },
            }),

            newPassword: builder.mutation({
                query: (request: any) => ({
                    url: `${authEndpoint.newPassword}`,
                    method: 'POST',
                    data: request,
                }),
                async onQueryStarted(unknown, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                    } catch (error) {}
                },
            }),
            refreshToken: builder.mutation({
                query: (newData: any) => ({
                    url: `${authEndpoint.refreshToken}`,
                    method: 'POST',
                    data: newData,
                }),
                async onQueryStarted(unknown, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        await storageServices.setItem(
                            'token',
                            JSON.stringify(data?.token),
                        );
                        await storageServices.setItem(
                            'user',
                            JSON.stringify(data?.user),
                        );
                        localStorage.setItem(
                            'currentRole',
                            JSON.stringify(data?.user?.roles[0]),
                        );
                    } catch (error) {
                        history.push('/login');
                        await storageServices.removeItem('token');
                        await storageServices.removeItem('user');
                        window.location.reload();
                    }
                },
            }),
        };
    },
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useNewPasswordMutation,
    useRefreshTokenMutation,
} = AuthApiService;
