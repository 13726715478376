import React, { useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import { useResetPasswordMutation } from "../api/auth-api-service";
import { history } from "../history";
import { resetSchema } from "./form-validation";

function ForgetPasswordForm() {
  const [isReset, setIsReset] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetSchema),
    mode: "onBlur",
  });
  const [reset, { data, isSuccess, isError, isLoading }] =
    useResetPasswordMutation();
  const [passwordShown, setPasswordShown] = useState(false);

  const onReset = async (request: any) => {
    await reset(request);
    setIsReset(true);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div
      style={{ height: "100vh", backgroundColor: "#fafbf6" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="w-75 d-flex justify-content-center align-items-center">
        <div className="w-50 mx-4">
          <div style={{ textAlign: "center" }}>
            <h4>Reset Password</h4>
          </div>
          <div>
            <Card className=" p-4 rounded w-100">
              <Form onSubmit={handleSubmit(onReset)}>
                {!isSuccess && (
                  <>
                    <Form.Group className="mb-4">
                      <Form.Label className="text-blue">Email</Form.Label>
                      <Form.Control
                        size="lg"
                        isInvalid={errors?.email ? true : false}
                        {...register("email")}
                        type="text"
                        placeholder="Enter your email address"
                      ></Form.Control>
                      <Form.Control.Feedback>
                        {errors?.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {isError && (
                      <span className="text-danger mb-2">
                        Email address does not exist!
                      </span>
                    )}
                    <Button className="w-100" variant="primary" type="submit">
                      {isLoading ? (
                        <Spinner
                          style={{
                            height: "10px",
                            width: "10px",
                          }}
                          animation="border"
                          role="status"
                        ></Spinner>
                      ) : (
                        ""
                      )}{" "}
                      Reset
                    </Button>
                  </>
                )}

                {isSuccess && (
                  <span>Password reset link has been sent to your email </span>
                )}

                <div className="mt-4 mb-0">
                  <Link
                    to="/login"
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      textAlign: "center",
                    }}
                  >
                    <p>Return to login</p>
                  </Link>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPasswordForm;
