import { render } from '@testing-library/react'
import { ChangePassword } from 'features/model/change-password'
import MessagingServices from 'shared/messaging-services'
import { collectionQueryBuilder } from 'shared/model/collection-query-builder'
import { dashboardApiService } from 'store/dashboardApi'
import { ProfileEndpoints } from './profile-end-points'
const ProfileApiService = dashboardApiService.injectEndpoints({
  endpoints(builder) {
    return {
      updateProfile: builder.mutation({
        query: (profile: any) => ({
          url: ProfileEndpoints.updateProfile,
          method: 'post',
          data: profile,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            render(
              <MessagingServices
                message="Profile Updated Successfully"
                show={true}
                type={'success'}
              />,
            )
          } catch (error) {
            render(
              <MessagingServices
                message="Something went wrong unable to update profile"
                show={true}
                type={'error'}
              />,
            )
          }
        },
      }),
      changePassword: builder.mutation({
        query: (password: ChangePassword) => ({
          url: ProfileEndpoints.changePassword,
          method: 'post',
          data: password,
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            render(
              <MessagingServices
                message="Password Updated Successfully"
                show={true}
                type={'success'}
              />,
            )
          } catch (error) {
            render(
              <MessagingServices
                message="Something went wrong unable to update your password"
                show={true}
                type={'error'}
              />,
            )
          }
        },
      }),

      getUserProfile: builder.query({
        query: () => ({
          url: ProfileEndpoints.getUserProfile,
          method: 'get',
        }),
      }),
    }
  },
})

export const {
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useGetUserProfileQuery,
} = ProfileApiService
