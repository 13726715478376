import {Owner} from 'features/model/owner';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {yupResolver} from '@hookform/resolvers/yup';
import {
    IconDeviceFloppy,
    IconEdit,
    IconSend,
    IconSquareX,
    IconTrash,
} from '@tabler/icons';

import {Notification, Receivers} from '../../model/notification';
import {useSendMutation} from '../api/notification-api-services';
import {schema} from './form-validation';

interface NotificationFormProps {
    selectedItem?: any;
    selectedItemLoading?: boolean;
}
function NotificationForm(props: NotificationFormProps) {
    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue,
    } = useForm<Notification>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const [isCollapsed, setIsCollapsed] = useState(false);

    const [send, {isLoading: sending}] = useSendMutation();

    const onSend: SubmitHandler<Notification> = (message: Notification) => {
        send(message);
    };

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    useEffect(() => {
        if (props?.selectedItem) reset(props?.selectedItem);
    }, [props?.selectedItem]);
    return (
        <Card>
            <Card.Header className='d-flex justify-content-between'>
                <div> Notification</div>
                <div className='d-flex'>
                    <Button size='sm' onClick={onCollapsed}>
                        {isCollapsed ? 'Expand' : 'Collapse'}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body className={isCollapsed ? 'd-none' : ''}>
                <Form onSubmit={handleSubmit(onSend)}>
                    <div className='d-flex mb-4'>
                        <div className='w-50 '>
                            <Form.Group controlId='title'>
                                <div className='form-group required'>
                                    <Form.Label className='control-label'>
                                        Title
                                    </Form.Label>
                                </div>
                                <Form.Control
                                    isInvalid={errors?.title ? true : false}
                                    {...register('title')}
                                    type='text'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors?.title?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='w-50 mx-4'>
                            <Form.Group controlId='to_whom'>
                                <div className='form-group required'>
                                    <Form.Label className='control-label'>
                                        To Whom
                                    </Form.Label>
                                </div>
                                <Form.Select
                                    isInvalid={errors?.to_whom ? true : false}
                                    {...register('to_whom')}
                                >
                                    <option></option>
                                    {Receivers.map((element) => {
                                        return (
                                            <option
                                                value={element}
                                                label={element}
                                            ></option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                    {errors?.to_whom?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>

                    <div className='d-flex mb-4'>
                        <div className='w-50 '>
                            <Form.Group
                                className='mb-3'
                                controlId='formPhoneNumber'
                            >
                                <div className='form-group required'>
                                    <Form.Label className='control-label'>
                                        Body
                                    </Form.Label>
                                </div>
                                <Form.Control
                                    as='textarea'
                                    rows={5}
                                    isInvalid={errors?.body ? true : false}
                                    {...register('body')}
                                    type='text'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors?.body?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>

                    <VButton
                        type='submit'
                        variant='primary'
                        isLoading={sending}
                    >
                        {' '}
                        <IconSend /> Send
                    </VButton>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default NotificationForm;
