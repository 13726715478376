import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {vehicleEndpoint} from './vehicle-endpoint';

const VehicleApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listVehicle: builder.query({
                query: () => ({
                    url: `${vehicleEndpoint.list}`,
                    method: 'get',
                }),
            }),
            getVehicleByOwner: builder.query({
                query: (owner_id) => ({
                    url: `${vehicleEndpoint.getVehicleByOwner}?owner_id=${owner_id}`,
                    method: 'get',
                }),
            }),
            getVehicleById: builder.query({
                query: (id: string) => ({
                    url: `${vehicleEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            createVehicle: builder.mutation({
                query: (newData: any) => ({
                    url: `${vehicleEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(auth, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                show={true}
                                type='success'
                                message='Vehicle created successfully'
                            />,
                        );
                    } catch (err) {
                 
                        render(
                            <MessagingServices
                                show={true}
                                type='error'
                                message={err.error.data.message}
                            />,
                        );
                    }
                },
            }),
            updateVehicle: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${vehicleEndpoint.update}?vehicle_id=${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Vehicle updated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteVehicle: builder.mutation({
                query: (id: string) => ({
                    url: `${vehicleEndpoint.delete}`,
                    method: 'delete',
                }),
            }),

            listVehicleOwner: builder.query({
                query: (query?: any) => ({
                    url: `${vehicleEndpoint.listOwner}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getVehicleOwnerById: builder.query({
                query: (query: any) => ({
                    url: `${vehicleEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),

            uploadVehicleDocument: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${vehicleEndpoint.uploadDocument}?vehicle_id=${updatedData.vehicle_id}`,
                    method: 'post',
                    data: updatedData.file,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        render(
                            <MessagingServices
                                message='Document Uploaded Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Document not Uploaded'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            listOwnVehicleCategory: builder.query({
                query: () => ({
                    url: `${vehicleEndpoint.listVehicleCategory}`,
                    method: 'get',
                }),
            }),
            activateVehicle: builder.mutation({
                query: (id: string) => ({
                    url: `${vehicleEndpoint.activateVehicle}`,
                    method: 'post',
                    data: {
                        id: id,
                    },
                }),
                async onQueryStarted(id, {dispatch: queryFulfilled}) {
                    try {
                        render(
                            <MessagingServices
                                message='Vehicle Activated Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='something went wrong Vehicle Activation Failed'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            blockVehicle: builder.mutation({
                query: (id: string) => ({
                    url: `${vehicleEndpoint.blockVehicle}`,
                    method: 'post',
                    data: {
                        id: id,
                    },
                }),
                async onQueryStarted(id, {dispatch: queryFulfilled}) {
                    try {
                        render(
                            <MessagingServices
                                message='Vehicle Blocked Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='something went wrong unable to block a vehicle'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useListVehicleQuery,
    useGetVehicleByIdQuery,
    useGetVehicleByOwnerQuery,
    useCreateVehicleMutation,
    useUpdateVehicleMutation,
    useDeleteVehicleMutation,
    useLazyGetVehicleOwnerByIdQuery,
    useLazyListVehicleOwnerQuery,
    useUploadVehicleDocumentMutation,
    useListOwnVehicleCategoryQuery,
    useActivateVehicleMutation,
    useBlockVehicleMutation,
} = VehicleApiService;
