export const providerEndpoint = {
    list: `drivers/get-drivers`,
    detail: `drivers/get-driver`,
    create: `drivers/create-driver`,
    update: `drivers/update-driver`,
    delete: `drivers/delete-driver`,
    updateDriverPhoto: `drivers/update-profile-image`,
    listOwner: `owners/get-owners`,
    getOwnerById: `owners/get-owner`,
    listVehicle: `vehicles/get-vehicles`,
    getVehicleById: `vehicles/get-vehicle`,
    getVehicleByOwner: `vehicles/get-vehicles-by-owner`,

    activateDriver: `drivers/activate-driver`,
    blockDriver: `drivers/block-driver`,

    //document
    uploadDocument: `drivers/update-document`,

    //reassign
    reassign: `drivers/reassign-vehicle`,
};
