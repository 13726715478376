import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {PDF} from '../../../shared/entity/models';
import {useLazyListVehicleOwnerQuery} from '../api/vehicle-api-service';

function VehicleOwnerList() {
    const pdfConfig: PDF = {
        visibleColumn: [
            {
                name: 'ID',
                key: 'id',
            },
            {
                name: 'Name',
                key: 'name',
            },
            {
                key: ['address', 'city'],
                name: 'City',
                hasChilde: true,
            },
        ],
        fileName: 'owners',
        orientation: 'landscape',
        title: 'owners List',
    };
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['created_by'],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['first_name', 'last_name', 'phone_number'],
    });

    const [getData, result] = useLazyListVehicleOwnerQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(result?.isLoading);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: '/vehicle',
        title: 'Owners List',
        detailUrl: '/vehicle/owner-detail',
        showNewButton: false,
        visibleColumn: [
            {key: 'first_name', name: 'First Name'},
            {key: 'last_name', name: 'Last Name'},
            // {key: 'email', name: 'Email'},
            {key: 'phone_number', name: 'Phone Number'},
            {key: 'gender', name: 'Gender'},
            {
                key: ['created_by', 'first_name'],
                name: 'Registered By',
                postFix: {key: ['created_by', 'last_name']},
            },
            {key: 'created_at', name: 'Registered At', isDate: true},
            {key: 'enabled', name: 'Status'},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <>
            <EntityList
                itemsLoading={loading}
                items={items}
                total={total}
                config={config}
                search={onSearch}
                pagination={onPagination}
                filter={onFilter}
                pdfConfig={pdfConfig}
            />
        </>
    );
}

export default VehicleOwnerList;
