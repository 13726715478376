import Error404 from 'shared/component/error404';

import LoginForm from './component/lofin-form';
import { userService } from './user-services';

interface Props {
  component: React.ComponentType
  path?: string
  roles?: Array<any>
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  roles,
}) => {
  const user = userService.currentRole
  const userHasRequiredRole = user && roles?.includes(user) ? true : false

  if (user && userHasRequiredRole) {
    return <RouteComponent />
  }

  if (user && !userHasRequiredRole) {
    return <Error404 />
  } else {
    return <LoginForm />
  }
}
