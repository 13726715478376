import React, { useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";

import ModalFileViewer from "shared/component/modal-file-viewer";
import { useParams } from "react-router-dom";
import { useChangeVolunteerPasswordMutation } from "../api/public-provider-services";
import { useForm } from "react-hook-form";
import moment from "moment";
import { VButton } from "shared/component/button";
import { IconDeviceFloppy } from "@tabler/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePassword } from "../components/form-validation";
import { changePasswordSchema } from "../components/form-validation";

function ChangeVolunteerPassword() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  //   const [updateProfile, { isLoading: profileUpdating }] =
  //     useUpdateProfileMutation();
  const [changePassword, { isLoading: passwordUpdating }] =
    useChangeVolunteerPasswordMutation();

  const onCollapsed = () => {
    var isExpand = !isCollapsed;
    setIsCollapsed(isExpand);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ChangePassword>({
    resolver: yupResolver(changePasswordSchema),
    mode: "onBlur",
  });

  const { id } = useParams();

  const onChangePassword = (password: ChangePassword) => {
    const data = {
      ...password,
      driverId: id,
    };
    changePassword(data);
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div> Change Password </div>
            <div className="d-flex">
              <Button size="sm" onClick={onCollapsed}>
                {isCollapsed ? "Expand" : "Collapse"}
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body className={isCollapsed ? "d-none" : ""}>
          <Form onSubmit={handleSubmit(onChangePassword)}>
            <div className="d-flex justify-content-between">
              <div className="w-50">
                <div className="d-flex mb-2">
                  <div className="w-100 mx-1">
                    <Form.Group className="mb-2" controlId="formNewPassword">
                      <div className="form-group required">
                        <Form.Label className="control-label">
                          New Password
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="password"
                        placeholder="enter new password"
                        {...register("new_password")}
                        isInvalid={errors?.new_password ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.new_password?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-2"
                      controlId="formConfirmPassword"
                    >
                      <div className="form-group required">
                        <Form.Label className="control-label">
                          confirm Password
                        </Form.Label>
                      </div>
                      <Form.Control
                        type="password"
                        placeholder="confirm password"
                        {...register("confirm_password")}
                        isInvalid={errors?.confirm_password ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.confirm_password?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                {/* </Card.Body>
                  </Card> */}
                <div className="mt-4">
                  <VButton
                    // onClick={onChangePassword}
                    type="submit"
                    variant="primary"
                    isLoading={passwordUpdating}
                  >
                    {/* <IconDeviceFloppy /> */}
                    Change Password
                  </VButton>
                </div>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default ChangeVolunteerPassword;
