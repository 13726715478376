import React from 'react';
import {useParams} from 'react-router-dom';

import {useDetailEmergencyReportQuery} from '../api/emergency-report-api-service';
import EmergencyReportDetail from './emergency-report-detail';

function EmergencyReportLandingPage() {
    const {id} = useParams();
    const {data, isLoading} = useDetailEmergencyReportQuery(id ? id : '');

    return (
        <EmergencyReportDetail
            selectedRequest={data}
            selectedRequestLoading={isLoading}
        />
    );
}

export default EmergencyReportLandingPage;
