import React from 'react';
import { BrowserRouter, useParams, useRoutes } from 'react-router-dom';

import { useListOwnVehicleCategoryQuery } from '../api/vehicle-api-service';
import VehicleFrom from '../component/vehicle-form';

function NewVehicle() {
  const { data, isLoading } = useListOwnVehicleCategoryQuery('')
  const { id } = useParams()

  return (
    <VehicleFrom
      vehicleCategory={{ items: data?.items, isLoading: isLoading }}
      ownerId={id}
      viewMode="new"
      title="Register new Vehicle"
    />
  )
}

export default NewVehicle
