function DefaultDashboard() {
    return (
        <div
            style={{marginTop: '250px'}}
            className='h-100 w-100 d-flex justify-content-center justify-items-center'
        >
            <span>You are not authorized to access this page !!!</span>
        </div>
    );
}

export default DefaultDashboard;
