export function getItem(key: any) {
    return localStorage.getItem(key);
}

export function setItem(key: string, data: any) {
    return localStorage.setItem(key, data);
}

export function removeItem(key: string) {
    return localStorage.removeItem(key);
}
const clear = async () => {
    await localStorage.clear();
};

export const storageServices = {
    getItem,
    setItem,
    removeItem,
    clear,
};
