import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import {history} from './history';
import { baseUrl } from 'config/constants';

export const authHeaderService = {
    get getAuthHeader(): any {
        const token = localStorage.getItem('token');
        return token ? JSON.parse(token) : null;
    },
    get refreshToken(): any {
        const refreshTok = localStorage.getItem('refreshToken');
        return refreshTok ? JSON.parse(refreshTok) : null;
    },
};

const getRefreshToken = async () => {
    const token = authHeaderService.getAuthHeader;
    if (token === null) {
    } else {
        const token: any = jwt_decode(
            JSON.parse(authHeaderService.getAuthHeader),
        );
        const isExpired = dayjs.unix(token.exp).diff(dayjs()) < 1;

        if (!isExpired) return token;

        history.push('/login');
        const response = await axios.post(
            `${baseUrl}/refreshToken`,
            {
                refreshToken: token.refreshToken,
            },
        );
        localStorage.setItem('token', JSON.stringify(response.data));
    }
};
// getRefreshToken()
