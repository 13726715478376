import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import EmptyState from 'shared/component/empty-state';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyGetRequestReportQuery} from '../api/report-api-services';
import AreaCharts from '../component/area-chart';

function RequestReport() {
    const [getData, result] = useLazyGetRequestReportQuery();
    const [items, setItems] = useState<any[]>(result?.data);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [type, setType] = useState<string>('d');

    const [request, setRequest] = useState<CollectionQuery>({
        groupBy: 'status',
    });

    useEffect(() => {
        if (startDate && endDate) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                    ],
                };
            });
        }
    }, [type, startDate, endDate]);

    useEffect(() => {
        getData(request);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data);
        setLoading(result?.isLoading);
    }, [result]);

    return (
        <Card className='mt-2'>
            <Card.Header>
                <div>Request</div>
            </Card.Header>
            <Card.Body>
                <div className='d-flex justify-content-center items-center'>
                    <div className='mx-4'>
                        <label>Start Date</label>
                        <input
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                            value={startDate}
                            style={{width: '100%', padding: '0.5rem'}}
                            type='date'
                        />
                    </div>

                    <div className='mx-4'>
                        <label>End Date</label>
                        <input
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                            value={endDate}
                            style={{width: '100%', padding: '0.5rem'}}
                            type='date'
                            min={startDate}
                        />
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    <div>
                        {/* <Barchart detail={items} xAxisKey={'status'} /> */}
                        {items?.length > 0 ? (
                            <AreaCharts items={items} xAxis={'status'} />
                        ) : (
                            <EmptyState />
                        )}
                    </div>

                    <div>
                        {/* <Piechart detail={items} /> */}

                        {/* <AreaCharts /> */}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default RequestReport;
