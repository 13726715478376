import React, {useEffect, useState} from 'react';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListPublicProviderForCreditQuery} from '../api/bank-api-service';

function BankProviderList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        searchFrom: ['first_name', 'last_name', 'email', 'phone_number'],
    });

    const [getData, result] = useLazyListPublicProviderForCreditQuery();
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: 'credit-driver',
        title: 'Drivers List',
        detailUrl: '/bank-credit/detail',
        showNewButton: false,
        visibleColumn: [
            {key: 'first_name', name: 'First Name'},
            {key: 'last_name', name: 'Last Name'},
            {key: 'email', name: 'Email'},
            {key: 'phone_number', name: 'Phone Number'},
        ],
        filter: [
            [
                {
                    field: 'is_active',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'is_active',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}

export default BankProviderList;
