import React, {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {userService} from '../../../services/auth/user-services';
import {useLazyListUserQuery} from '../api/user-api-services';

function UserList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        includes: ['created_by'],
        searchFrom: ['first_name', 'last_name', 'phone_number'],
    });

    const [getData, result] = useLazyListUserQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);

    useEffect(() => {
        setItems(
            result?.data?.items.filter(
                (item) => item.id !== userService.currentUserId,
            ),
        );
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: '/users',
        title: 'Users List',
        showNewButton: true,
        visibleColumn: [
            {key: 'first_name', name: 'First Name'},
            {key: 'last_name', name: 'Last Name'},
            {key: 'email', name: 'Email'},
            {key: 'phone_number', name: 'Phone Number'},
            {
                key: ['created_by', 'first_name'],
                name: 'Registered By',
                postFix: {key: ['created_by', 'last_name']},
            },
            {key: 'created_at', name: 'Registered At', isDate: true},
            {key: 'enabled', name: 'Status'},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={result?.data?.items || []}
            total={result?.data?.total || 0}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default UserList;
