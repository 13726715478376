import { GenderTypes, Owner } from "features/model/owner";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { VButton } from "shared/component/button";
import SpinnerForPages from "shared/component/spinner-for-pages";

import { yupResolver } from "@hookform/resolvers/yup";
import { IconDeviceFloppy, IconEdit, IconTrash } from "@tabler/icons";

import {
  useActivateOwnerMutation,
  useBlockOwnerMutation,
  useCreateOwnerMutation,
  useDeleteOwnerMutation,
  useUpdateOwnerMutation,
} from "../api/owner-api-service";
import { schema } from "./form-validation";

export interface OwnerFormProps {
  viewMode: string;
  title: string;
  selectedItem?: any;
  selectedItemLoading?: boolean;
}
function OwnerForm(props: OwnerFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Owner>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [enabled, setEnabled] = useState(props?.selectedItem?.enabled);

  const [addNewOwner, { isLoading: creating }] = useCreateOwnerMutation();
  const [updateOwner, { isLoading: updating }] = useUpdateOwnerMutation();
  const [deleteOwner, { isLoading: deleting }] = useDeleteOwnerMutation();
  const [activateOwner, { isLoading: activating }] = useActivateOwnerMutation();
  const [blockOwner, { isLoading: blocking }] = useBlockOwnerMutation();

  const onCreate: SubmitHandler<Owner> = (newOwner: Owner) => {
    addNewOwner(newOwner);
    setShouldRedirect(true);
  };

  const onUpdate: SubmitHandler<Owner> = (updatedOwner: Owner) => {
    updateOwner(updatedOwner);
    setShouldRedirect(true);
  };

  // const onDelete= () => {
  //   deleteOwner(props.selectedItem.id);
  // }

  const onActivate = () => {
    activateOwner(props.selectedItem.id);
    setEnabled((prevState) => !prevState);
    setShouldRedirect(true);
  };

  const onBlock = () => {
    blockOwner(props.selectedItem.id);
    setEnabled((prevState) => !prevState);
    setShouldRedirect(true);
  };

  useEffect(() => {
    setEnabled(props?.selectedItem?.enabled);
  }, [props?.selectedItem?.enabled]);

  const onCollapsed = () => {
    var isExpand = !isCollapsed;
    setIsCollapsed(isExpand);
  };

  useEffect(() => {
    if (props.selectedItem) {
      reset(props.selectedItem);
    }
    setIsLoading(props?.selectedItemLoading);
  }, [props.selectedItem, reset, props?.selectedItemLoading]);

  return (
    <>
      {isLoading ? (
        <SpinnerForPages />
      ) : (
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div> {props.title}</div>
            <div className="d-flex">
              {/* <Link to='/provider' className='text-black'><IconSquareX /></Link> */}
              <Button size="sm" onClick={onCollapsed}>
                {isCollapsed ? "Expand" : "Collapse"}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className={isCollapsed ? "d-none" : ""}>
            <Form
              onSubmit={
                props.viewMode === "detail"
                  ? handleSubmit(onUpdate)
                  : handleSubmit(onCreate)
              }
            >
              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group controlId="formFirstName">
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        First Name
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.first_name ? true : false}
                      {...register("first_name")}
                      type="text"
                      placeholder="enter first name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.first_name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="w-50 mx-4">
                  <Form.Group controlId="formLastName">
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Last Name
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.last_name ? true : false}
                      {...register("last_name")}
                      type="text"
                      placeholder="enter last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.last_name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group className="mb-3" controlId="formPhoneNumber">
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Phone number
                      </Form.Label>
                    </div>

                    <Form.Control
                      isInvalid={errors?.phone_number ? true : false}
                      {...register("phone_number")}
                      type="text"
                      placeholder="enter phone number"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.phone_number?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGender">
                    <div className="form-group">
                      <Form.Label className="control-label">Gender</Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.gender ? true : false}
                      {...register("gender")}
                      type="text"
                      placeholder="enter gender"
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors?.gender?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group
                  className="mb-3"
                  controlId="formEmergencyContactNumber"
                >
                  <Form.Label>Emergency contact number</Form.Label>
                  <Form.Control
                    {...register('emergencyContactNumber')}
                    type="text"
                    placeholder="enter emergence contact number"
                  />
                </Form.Group> */}

                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      {...register("email")}
                      type="text"
                      placeholder="enter email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formTin">
                    <div className="form-group">
                      <Form.Label className="control-label">
                        TIN number
                      </Form.Label>
                    </div>
                    <Form.Control
                      {...register("tin")}
                      type="text"
                      placeholder="enter tin"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.tin?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex mt-5">
                    <VButton
                      type="submit"
                      disabled={props.viewMode === "detail"}
                      variant="primary"
                      isLoading={creating}
                    >
                      {" "}
                      <IconDeviceFloppy /> Save
                    </VButton>
                    <VButton
                      type="submit"
                      disabled={props.viewMode === "new"}
                      className="mx-2"
                      variant="warning"
                      isLoading={updating}
                    >
                      {" "}
                      <IconEdit />
                      Update
                    </VButton>

                    {props?.selectedItem ? (
                      enabled ? (
                        <VButton
                          onClick={onBlock}
                          disabled={props.viewMode === "new"}
                          variant="danger"
                          isLoading={blocking}
                          className="mr-2"
                        >
                          {" "}
                          <IconTrash />
                          Block
                        </VButton>
                      ) : (
                        <VButton
                          onClick={onActivate}
                          disabled={props.viewMode === "new"}
                          variant="success"
                          isLoading={activating}
                          className="mr-2"
                        >
                          <IconTrash />
                          Activate
                        </VButton>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="w-50 mx-4">
                  <Card>
                    <Card.Header>Address</Card.Header>
                    <Card.Body>
                      <Form.Group className="mb-2" controlId="formCity">
                        <div className="form-group required">
                          <Form.Label className="control-label">
                            City
                          </Form.Label>
                        </div>
                        <Form.Control
                          isInvalid={errors?.address?.city ? true : false}
                          {...register("address.city")}
                          type="text"
                          placeholder="enter city"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.address?.city?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formSubCity">
                        <div className="form-group required">
                          <Form.Label className="control-label">
                            State
                          </Form.Label>
                        </div>
                        <Form.Control
                          isInvalid={errors?.address?.sub_city ? true : false}
                          {...register("address.sub_city")}
                          type="text"
                          placeholder="enter state"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.address?.sub_city?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-2" controlId="formWoreda">
                        <div className="form-group required">
                          <Form.Label className="control-label">
                            Street Address
                          </Form.Label>
                        </div>
                        <Form.Control
                          isInvalid={errors?.address?.woreda ? true : false}
                          {...register("address.woreda")}
                          type="text"
                          placeholder="enter street address"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.address?.woreda?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formHouseNumber">
                        <div className="form-group">
                          <Form.Label className="control-label">
                            Apartment/Suite Number
                          </Form.Label>
                        </div>
                        <Form.Control
                          isInvalid={
                            errors?.address?.house_number ? true : false
                          }
                          {...register("address.house_number")}
                          type="text"
                          placeholder="enter apartment/suite number"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.address?.house_number?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default OwnerForm;
